import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormStepContainer = lazy(
  () => import("./form-step-container-BZfbkt6Z.js").then((module) => ({
    default: module.FormStepContainer
  }))
);
function FormStepContainer({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormStepContainer, { ...props });
}
const useFormStepContainerContent = () => {
  return useMemo(
    () => ({
      formStepContainer: {
        component: FormStepContainer
      }
    }),
    []
  );
};
export {
  LazyFormStepContainer as FormStepContainer,
  useFormStepContainerContent
};
